import React from 'react';
import Nav from './components/Nav';
import About from './components/About';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Contact from './components/Contact';
import { Hero } from './components/Hero';

const App: React.FC = () => {
  return (
    <>
      <Nav />
      <Hero />

      <main id="main">
        <About />
        <Experience />
        <Skills />
        <Contact />
      </main>


    </>
  );
}

export default App;
