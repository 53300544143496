import React from "react";

const Nav: React.FC = () => {
    return (
        <>
            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center justify-content-between" style={{backgroundColor: "#000"}}>

                    {/* <h1 className="logo"><a href="/">SurajitG</a></h1> */}
                    {/* <!-- Uncomment below if you prefer to use an image logo --> */}
                    <a href="index.html" className="logo"><img src="img/dev.jpg" alt="" className="img-fluid" /></a>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                            <li><a className="nav-link scrollto" href="#about">About</a></li>
                            <li><a className="nav-link scrollto" href="#exp">Experience</a></li>
                            <li><a className="nav-link scrollto " href="#work">Skills</a></li>
                            {/* <li><a className="nav-link scrollto " href="#blog">Blog</a></li> */}
                            <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                    {/* <!-- .navbar --> */}

                </div>
            </header>
            {/* <!-- End Header --> */}

        </>
    );

}

export default Nav;