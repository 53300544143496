import React from "react";

const About: React.FC = () => {
    return (
        <>
            <section id="about" className="about-mf sect-pt4 route">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="box-shadow-full">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-5">
                                                <div className="about-img">
                                                    <img src="img/dev.jpg" className="img-fluid rounded b-shadow-a" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-7">
                                                <div className="about-info">
                                                    <p><span className="title-s">Name: </span> <span>Surajit Ghosh</span></p>
                                                    <p><span className="title-s">Profile: </span> <span>Cloud Solution Architect</span></p>
                                                    <p><span className="title-s">Email: </span> <span>contact@surajitg.dev</span></p>
                                                    <p><span className="title-s">Phone: </span> <span>+91 99860 29057</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="skill-mf">
                                            <p className="title-s">Skill</p>
                                            <span>Cloud Architecture</span> <span className="pull-right">85%</span>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={85} aria-valuemin={0} aria-valuemax={100} style={{ width: "85%" }}></div>
                                            </div>
                                            <span>Microservices</span> <span className="pull-right">80%</span>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} style={{ width: "75%" }}></div>
                                            </div>
                                            <span>Data Architecture</span> <span className="pull-right">50%</span>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: "50%" }}></div>
                                            </div>
                                            <span>DevSecOps</span> <span className="pull-right">90%</span>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} style={{ width: "90%" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-me pt-4 pt-md-0">
                                            <div className="title-box-2">
                                                <h5 className="title-left">
                                                    About me
                                                </h5>
                                            </div>
                                            <p className="lead">
                                                I am experienced full stack software architect in microsoft technologies to provide high performance,
                                                secure and reliable solutions to meet business results. Iterative approaches to corporate strategy
                                                foster collaborative thinking to further the overall value proposition.
                                            </p>
                                            <p className="lead">
                                                Create and strategize migration of old/logacy applications to be cloud ready and/or cloud native to
                                                realize benefit of cloud in enterprise landscape.
                                            </p>
                                            <p className="lead">
                                                I provide technical leadership to local engagement towards implementing product vision and roadmap,
                                                serving as an architect to the product development team, by evaluating and proposing technical
                                                alternatives for resolving business and technology issues.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End About Section --> */}
        </>
    );
}

export default About;