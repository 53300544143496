import React from "react";

const Experience: React.FC = () => {
    return (
        <>
            <section id="exp" className="services-mf pt-5 route">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="title-box text-center">
                                <h3 className="title-a">
                                    Experience
                                </h3>
                                <p className="subtitle-a">
                                    Various role I have played till now
                                </p>
                                <div className="line-mf"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <span className="ico-circle"><i className="bi bi-briefcase"></i></span>
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Cloud Architect</h2>
                                    <p className="s-description text-center">
                                        Architect and strategize migration of old/logacy applications to be cloud ready
                                        and/or cloud native to realize benefit of cloud in enterprise landscape.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <span className="ico-circle"><i className="bi bi-card-checklist"></i></span>
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Technology Architect</h2>
                                    <p className="s-description text-center">
                                        Provide technical leadership to local engagement towards implementing product vision and roadmap,
                                        serving as an architect to the product development team, by evaluating and proposing technical
                                        alternatives for resolving business and technology issues.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <span className="ico-circle"><i className="bi bi-bar-chart"></i></span>
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Application Architect</h2>
                                    <p className="s-description text-center">
                                    Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. 
                                    Override the digital divide with additional clickthroughs from DevOps. Nanotechnology 
                                    immersion along the information highway will close the loop on focusing solely on the bottom line.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <span className="ico-circle"><i className="bi bi-binoculars"></i></span>
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Development Lead</h2>
                                    <p className="s-description text-center">
                                    Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. 
                                    Override the digital divide with additional clickthroughs from DevOps. Nanotechnology 
                                    immersion along the information highway will close the loop on focusing solely on the bottom line.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <span className="ico-circle"><i className="bi bi-brightness-high"></i></span>
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Lead Developer</h2>
                                    <p className="s-description text-center">
                                    Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. 
                                    Override the digital divide with additional clickthroughs from DevOps. Nanotechnology 
                                    immersion along the information highway will close the loop on focusing solely on the bottom line.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-box">
                                <div className="service-ico">
                                    <span className="ico-circle"><i className="bi bi-calendar4-week"></i></span>
                                </div>
                                <div className="service-content">
                                    <h2 className="s-title">Developer</h2>
                                    <p className="s-description text-center">
                                    Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Services Section --> */}
        </>
    );
}

export default Experience;