import React from "react";

export const Hero: React.FC = () => {
    return (
        <>
            <div id="hero" className="hero route bg-image" style={{ backgroundColor: "#000" }}>
                <div className="overlay-itro"></div>
                <div className="hero-content display-table">
                    <div className="table-cell">
                        <div className="container">
                            {/* <!--<p className="display-6 color-d">Hello, world!</p>--> */}
                            <h1 className="hero-title mb-4">I am Surajit Ghosh</h1>
                            {/* <p className="hero-subtitle"><span className="typed" data-typed-items="Architect, Developer, Data Architect, DevOps"></span></p> */}
                            <p className="pt-3"><a className="btn btn-primary btn js-scroll px-4" href="#about" role="button">Know More</a></p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Hero Section --> */}
        </>
    );
}